import assignIn from "lodash/assignIn";
import { Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

import { UpdateAnalysisOutputDto } from "@rollup-api/models/analysis";
import { updateAnalysisOutput } from "@rollup-api/utils";

import { AnalysisStore } from "./AnalysisStore";

export const AnalysisOutputStore = types
  .model("AnalysisOutputStore", {
    id: types.identifier,
    value: types.optional(types.string, ""),
    unit: types.optional(types.string, ""),
    analysis: types.safeReference(types.late(() => AnalysisStore)),
    label: types.optional(types.string, "Untitled analysis output"),
    createdAt: types.optional(types.number, Date.now()),
    updatedAt: types.optional(types.number, Date.now()),
  })
  .actions(self => ({
    patch(update: UpdateAnalysisOutputDto) {
      // Prevent updating of fixed properties
      const invalidFields = ["id", "analysis", "spreadsheet"];
      const updateKeys = Object.keys(update);
      for (const field of invalidFields) {
        if (updateKeys.includes(field)) {
          return false;
        }
      }

      try {
        assignIn(self, update);
        return true;
      } catch (err) {
        console.warn(err);
        return false;
      }
    },
    setLabel(label: string) {
      self.label = label;
      updateAnalysisOutput(self.id, { label });
    },
    setUnit(unit: string) {
      self.unit = unit;
      updateAnalysisOutput(self.id, { unit });
    },
    setValue(value: string) {
      self.value = value;
    },
    clearValue() {
      self.value = "";
    },
  }))
  .views(self => ({
    get analysisId() {
      return self.analysis?.id ?? "";
    },
    // Needed for rollup-engineering compatibility
    get codeBlockId() {
      return this.analysisId;
    },
    get textValue() {
      if (self.unit) {
        return `${self.value} ${self.unit}`;
      }
      return self.value;
    },
  }));

export interface IAnalysisOutput extends Instance<typeof AnalysisOutputStore> {}

export interface IAnalysisOutputSnapshotIn extends SnapshotIn<typeof AnalysisOutputStore> {}

interface IAnalysisOutputSnapshotOut extends SnapshotOut<typeof AnalysisOutputStore> {}

export interface IAnalysisOutputMobxType extends IType<IAnalysisOutputSnapshotIn, IAnalysisOutputSnapshotOut, IAnalysisOutput> {}
