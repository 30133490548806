import { useEffect, useState } from "react";
import { Colors, Divider, Icon, InputGroup, Intent, Label, Spinner } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useDebounceValue } from "usehooks-ts";

import { Button } from "@components/Button";
import { showToast } from "@components/UiLayers/toaster";
import appStore from "@store/AppStore";
import { objectKeysToCamelCase } from "@utilities";
import { rollupClient } from "src/core/api";
import { Text, TextVariant } from "src/ui/Text";

type PasswordLudsValidation = {
  hasDigit: boolean;
  hasLowerCase: boolean;
  hasSymbol: boolean;
  hasUpperCase: boolean;
  missingCharacters: number;
  missingComplexity: number;
};

const PasswordConfigs = () => {
  const [validatingPassword, setValidatingPassword] = useState(false);
  const [updatingPassword, setUpdatingPassword] = useState(false);
  const [passwordValid, setPasswordValid] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [passwordValidation, setPasswordValidation] = useState<PasswordLudsValidation>({
    hasDigit: true,
    hasLowerCase: true,
    hasSymbol: true,
    hasUpperCase: true,
    missingCharacters: 0,
    missingComplexity: 0,
  });
  const [currentPassword, setCurrentPassword] = useState("");
  const [debounceNewPassword] = useDebounceValue(newPassword, 400);
  const currentPasswordValid = appStore.userModel?.hasPassword ? currentPassword.length > 0 : true;

  useEffect(() => {
    if (debounceNewPassword) {
      setValidatingPassword(true);
      rollupClient.auth
        .checkPasswordStrength(debounceNewPassword, appStore.userModel?.email || "")
        .then(r => {
          setPasswordValid(r.data.valid_password);
          const result = objectKeysToCamelCase<PasswordLudsValidation>(r.data.luds_feedback);
          setPasswordValidation(result);
        })
        .finally(() => setValidatingPassword(false));
    } else {
      setPasswordValid(true);
    }
  }, [debounceNewPassword]);

  const handleUpdatePassword = () => {
    setUpdatingPassword(true);
    rollupClient.profiles
      .changePassword(newPassword, currentPassword)
      .then(() => showToast("Password updated successfully", Intent.SUCCESS))
      .catch(err => {
        const message = err.response.data.message || "Password update failed!";
        showToast(message, Intent.DANGER);
      })
      .finally(() => setUpdatingPassword(false));
  };

  return (
    <div>
      <Divider />
      <div className="user-details-area">
        <Text variant={TextVariant.H3}>Password</Text>
        <div className={classNames("user-details-area--password", { ["user-details-area--password-lg"]: appStore.userModel?.hasPassword })}>
          {appStore.userModel?.hasPassword && (
            <Label className="user-details-area--subsection--label">
              Your current password
              <InputGroup
                type="password"
                large
                value={currentPassword}
                onChange={event => setCurrentPassword(event.target.value)}
                placeholder="Current password"
              />
            </Label>
          )}
          <Label className="user-details-area--subsection--label">
            Your new password
            <InputGroup
              type="password"
              large
              rightElement={validatingPassword ? <Spinner size={16} /> : undefined}
              value={newPassword}
              onChange={event => setNewPassword(event.target.value)}
              placeholder="New password"
            />
            <div className="user-details-area--validation" style={{ opacity: newPassword && !passwordValid ? 1 : 0 }}>
              <div>
                <Icon
                  color={passwordValidation.missingCharacters ? Colors.RED3 : Colors.GREEN4}
                  icon={passwordValidation.missingCharacters ? "cross" : "tick"}
                />
                Contains at least 10 characters
              </div>
              <div>
                <Icon
                  color={passwordValidation.hasSymbol ? Colors.GREEN4 : Colors.RED3}
                  icon={passwordValidation.hasSymbol ? "tick" : "cross"}
                />
                Contains at least 1 special character
              </div>
              <div>
                <Icon
                  color={passwordValidation.hasUpperCase ? Colors.GREEN4 : Colors.RED3}
                  icon={passwordValidation.hasUpperCase ? "tick" : "cross"}
                />
                Contains at least 1 uppercase letter
              </div>
              <div>
                <Icon
                  color={passwordValidation.hasLowerCase ? Colors.GREEN4 : Colors.RED3}
                  icon={passwordValidation.hasLowerCase ? "tick" : "cross"}
                />
                Contains at least 1 lowercase letter
              </div>
              <div>
                <Icon
                  color={passwordValidation.hasDigit ? Colors.GREEN4 : Colors.RED3}
                  icon={passwordValidation.hasDigit ? "tick" : "cross"}
                />
                Contains at least 1 number
              </div>
            </div>
          </Label>
          <Button
            large
            className="user-details-area--save"
            intent="primary"
            onClick={handleUpdatePassword}
            disabled={!passwordValid || validatingPassword || !currentPasswordValid || !newPassword}
            loading={updatingPassword}
            e2eIdentifiers={["settings-account", "update-password"]}
          >
            Update password
          </Button>
        </div>
      </div>
    </div>
  );
};

export default observer(PasswordConfigs);
