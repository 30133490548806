import { Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

import { UpdateTableColumnDto } from "@rollup-api/models/table-column/updateTableColumnDto";
import { EntityType } from "@store/types";
import { resolveEntityLabel } from "@utilities";
import { applySanitizedSnapshot } from "@utilities/MobxUtils";

import { rollupClient } from "../core/api";

export const TableColumnStore = types
  .model("TableColumn", {
    id: types.identifier,
    metaColumn: types.maybeNull(types.string),
    entity: types.maybeNull(types.frozen<{ id: string; type: EntityType }>()),
    tableId: types.string,
    hide: types.maybe(types.boolean),
    width: types.maybe(types.number),
    orderIndex: types.number,
    rowGroup: types.maybe(types.boolean),
  })
  .actions(self => ({
    patch(update: UpdateTableColumnDto) {
      applySanitizedSnapshot(self, update, ["id", "entity", "tableId"]);
    },
    setWidth(width: number, disableNotification?: boolean) {
      self.width = width;

      if (!disableNotification) {
        rollupClient.tableColumns.update(self.tableId, self.id, { width });
      }
    },
    setOrderIndex(orderIndex: number, disableNotification?: boolean) {
      self.orderIndex = orderIndex;

      if (!disableNotification) {
        rollupClient.tableColumns.update(self.tableId, self.id, { orderIndex });
      }
    },
    setHide(hide: boolean, disableNotification?: boolean) {
      self.hide = hide;

      if (!disableNotification) {
        rollupClient.tableColumns.update(self.tableId, self.id, { hide: self.hide });
      }
    },
    toggleHide(disableNotification?: boolean) {
      this.setHide(!self.hide, disableNotification);
    },
    setRowGroup(rowGroup: boolean, disableNotification?: boolean) {
      self.rowGroup = rowGroup;

      if (!disableNotification) {
        rollupClient.tableColumns.update(self.tableId, self.id, { rowGroup });
      }
    },
  }))
  .views(self => ({
    get label(): string {
      if (self.metaColumn) {
        return self.metaColumn;
      } else if (self.entity) {
        return resolveEntityLabel(self.entity.id, self.entity.type) || "Untitled";
      }
      return "Unknown";
    },
  }));

export interface ITableColumn extends Instance<typeof TableColumnStore> {}
export interface ITableColumnSnapshotIn extends SnapshotIn<typeof TableColumnStore> {}
interface ITableColumnSnapshotOut extends SnapshotOut<typeof TableColumnStore> {}
export interface ITableColumnMobxType extends IType<ITableColumnSnapshotIn, ITableColumnSnapshotOut, ITableColumn> {}

// TODO implement real-time updates
// export function subscribeToBomColumnEvents(socket: Socket) {
//   socket.on("updateBomColumn", (data: { tableId: string; workspaceId: string; id: string; updateBomColumnDto: UpdateBomColumnDto }) => {
//     if (data.id && data.workspaceId === appStore.workspaceModel?.id && appStore.env.activeBomTableId === data.tableId) {
//       const bomTable = appStore.workspaceModel.bomTablesMap.get(data.tableId);
//       const bomColumn = bomTable?.columnMap.get(data.id);
//       if (bomTable?.tableGridApi) {
//         if (data.updateBomColumnDto.width) {
//           bomTable.tableGridApi.setColumnWidth(data.id, data.updateBomColumnDto.width);
//         }
//         if (data.updateBomColumnDto.visible !== undefined) {
//           bomTable.tableGridApi.setColumnsVisible([data.id], data.updateBomColumnDto.visible);
//         }
//       }
//       bomColumn?.patch(data.updateBomColumnDto);
//     }
//   });
// }
