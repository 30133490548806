import { MouseEvent } from "react";
import { Menu } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";

import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import BlockFilterableMenu from "@components/Shared/BlockFilterableMenu/BlockFilterableMenu";
import { IBlock } from "@store/BlockStore";
import { IRequirementsPage } from "@store/Requirements/RequirementsPageStore";
import { EntityType } from "@store/types";

interface IRequirementsActionMenuProps {
  reqPage: IRequirementsPage;
  onDelete(e: MouseEvent): void;
}

const RequirementsActionMenu = (props: IRequirementsActionMenuProps) => {
  const { reqPage, onDelete } = props;

  const onAddToBlockAttachments = (block: IBlock) => {
    block.addReferenceLink({
      entityId: reqPage.id,
      entityType: EntityType.RequirementsDocument,
      label: reqPage.label,
    });
  };

  return (
    <Menu>
      <MenuItem icon="paperclip" text="Add to Attachments" e2eIdentifiers="attach-requirements-to-block">
        <BlockFilterableMenu onSelect={onAddToBlockAttachments} />
      </MenuItem>
      <MenuItemDelete onDelete={onDelete} />
    </Menu>
  );
};

export default observer(RequirementsActionMenu);
