import { ProjectManagementIcon } from "@components/CustomIcon";
import UserInfo from "@components/UserInfo/UserInfo";
import { TNavigationLinkConfig, TNavigationLinkConfigs } from "@constants/navigation";
import { IconSource } from "@rollup-types/icons";
import { EPageName } from "@router/hooks/useAppNavigate";
import appStore from "@store/AppStore";
import { mergeE2EIdentifiers } from "@utilities/E2EUtils";
import { removeDisabledMenuItems } from "@utilities/NavigationUtils";

const getMyTasksMenuItem = (e2eSuffix: string): TNavigationLinkConfig => ({
  text: "My Tasks",
  icon: appStore.userModel ? <UserInfo user={appStore.userModel} size="small" disableTooltip avatarOnly /> : undefined,
  to: EPageName.ProjectManagementMyTasks,
  e2eIdentifiers: mergeE2EIdentifiers("my-tasks", e2eSuffix),
});

const getAllTasksMenuItem = (e2eSuffix: string): TNavigationLinkConfig => ({
  text: "All Tasks",
  icon: { source: IconSource.Custom, name: ProjectManagementIcon.Task },
  to: EPageName.ProjectManagementAllTasks,
  e2eIdentifiers: mergeE2EIdentifiers("all-tasks", e2eSuffix),
});

const getProjectsMenuItem = (e2eSuffix: string): TNavigationLinkConfig => ({
  text: "Projects",
  icon: { source: IconSource.Custom, name: ProjectManagementIcon.Project },
  to: EPageName.ProjectManagementProjects,
  e2eIdentifiers: mergeE2EIdentifiers("projects", e2eSuffix),
});

export const getPmLayoutMenuItems = (e2eSuffix = ""): TNavigationLinkConfigs => {
  const menuItems = [getMyTasksMenuItem(e2eSuffix), getAllTasksMenuItem(e2eSuffix), getProjectsMenuItem(e2eSuffix)];
  return removeDisabledMenuItems(menuItems);
};
