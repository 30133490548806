import { useEffect } from "react";
import { IntercomProps, useIntercom } from "react-use-intercom";
import { observer } from "mobx-react";

import appStore from "@store/AppStore";

const Intercom = () => {
  const { userModel, orgModel } = appStore;
  const { update: updateIntercomProps } = useIntercom();
  useEffect(() => {
    if (import.meta.env.VITE_NODE_ENV !== "test" && userModel) {
      const intercomProps: Partial<IntercomProps> = {
        name: userModel.displayName,
        userId: userModel.id,
        avatar: userModel.avatarUrl ? { type: "avatar", imageUrl: userModel.avatarUrl } : undefined,
      };
      if (orgModel?.info.id) {
        intercomProps.company = {
          companyId: orgModel.info.id,
          name: orgModel.info.name,
        };
      }
      updateIntercomProps(intercomProps);
    }
  }, [userModel, orgModel, updateIntercomProps]);
  return null;
};

export default observer(Intercom);
