import { useEffect, useState } from "react";
import { NonIdealState, Pre, Spinner } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { showApiErrorToast } from "@components/UiLayers/toaster";
import { ArtifactInfo, ExecutionResult } from "@rollup-api/models/analysis";
import { rollupClient } from "src/core/api";

function TextArtifactLog({ result, artifact }: { result: ExecutionResult; artifact: ArtifactInfo }) {
  const [isFetching, setIsFetching] = useState(true);
  const [log, setLog] = useState<string | null>(null);

  useEffect(() => {
    if (!artifact || !result) {
      setIsFetching(false);
    }

    setIsFetching(true);
    rollupClient.analysisModule.analyses
      .getTextAsset(result.analysisId, result.id, artifact.id)
      .then(text => {
        setLog(text);
      })
      .catch(error => {
        console.warn("Error fetching text artifact", error);
        showApiErrorToast("Error fetching log", error);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [artifact, result]);

  if (isFetching) {
    return <NonIdealState icon={<Spinner />} title="Fetching log" />;
  } else if (!artifact || !result) {
    return <NonIdealState icon="document" title="No log available" />;
  }

  return <Pre>{log}</Pre>;
}

export default observer(TextArtifactLog);
