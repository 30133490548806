import { KeyboardEvent } from "react";
import { useUpdateEditorContent } from "@hooks/useUpdateEditorContent";
import Link from "@tiptap/extension-link";
import { Placeholder } from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import { FocusPosition, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { GridApi, IRowNode } from "ag-grid-community";
import classNames from "classnames";
import { observer } from "mobx-react";

import { EditorContent } from "@components/EditorContent";
import EditorBubbleMenu from "@components/Reports/Editor/EditorBubbleMenu";
import { getMentionExtensions } from "@components/Reports/Editor/Extentions/Mention/MentionSuggestion";
import PreventEnter from "@components/Reports/Editor/Extentions/PreventEnter";
import { useBubbleMenu } from "@components/Reports/Editor/hooks/useBubbleMenu";
import { useCellAutoHeight } from "@components/Table/hooks/useCellAutoHeight";
import { ENTER_KEY, ESCAPE_KEY } from "@constants/keys";
import { RollupEditorType } from "@rollup-types/editor";
import appStore from "@store/AppStore";

import "./RichTextCellEditor.scss";

interface IRichTextCellEditorProps<T> {
  defaultValue?: string;
  placeholder?: string;
  className?: string;
  node: IRowNode<T>;
  api: GridApi<T>;
  resizeIfSmaller?: boolean;
  type?: RollupEditorType;
  autofocus?: FocusPosition;
  onCommit(value: string): void;
  onCancel?(): void;
  onValueChange?(value: string): void;
}

const getEditorTag = (type: RollupEditorType) => {
  switch (type) {
    case RollupEditorType.h1:
      return "h1";
    case RollupEditorType.h2:
      return "h2";
    case RollupEditorType.h3:
      return "h3";
    case RollupEditorType.h4:
      return "h4";
    case RollupEditorType.h5:
      return "h5";
    case RollupEditorType.h6:
      return "h6";
    case RollupEditorType.p:
    default:
      return "p";
  }
};

const RichTextCellEditor = <T,>(props: IRichTextCellEditorProps<T>) => {
  const { defaultValue = " ", placeholder = "", type = RollupEditorType.p, onValueChange } = props;
  const { className, node, api, resizeIfSmaller, autofocus, onCommit, onCancel } = props;
  const workspace = appStore.workspaceModel ?? undefined;
  const { onHide: onBubbleMenuHide, onShow, isOpen: bubbleNavIsOpen } = useBubbleMenu();

  const editor = useEditor({
    extensions: [StarterKit, Underline, Link, PreventEnter, ...getMentionExtensions({ workspace }), Placeholder.configure({ placeholder })],
    content: defaultValue,
    autofocus: autofocus,
    onUpdate: ({ editor }) => {
      if (editor.isEmpty) {
        const tag = getEditorTag(type);
        editor.commands.setContent(`<${tag}></${tag}>`);
        editor.commands.focus("start");
      }
      handleChange({ target: { innerText: editor.getText() } } as any);
      onValueChange?.(editor.getHTML());
    },
  });
  const value = editor?.getText() ?? defaultValue;
  const { containerRef, handleChange } = useCellAutoHeight({ disabled: !editor, value, node, api, resizeIfSmaller });

  const handleBubbleMenuHide = () => {
    onBubbleMenuHide();
  };

  useUpdateEditorContent({ content: editor?.getHTML() ?? defaultValue, editor, shouldNotUpdate: bubbleNavIsOpen });

  if (!editor) {
    return null;
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === ENTER_KEY) {
      onCommit(editor.getHTML());
      editor.commands.blur();
    } else if (event.key === ESCAPE_KEY) {
      editor.commands.setContent(defaultValue);
      onCancel?.();
      editor.commands.blur();
    }
  };

  return (
    <div ref={containerRef} className={classNames("rich-text-cell-editor", className)}>
      <EditorBubbleMenu className="rich-text-cell-editor--bubble-menu" onHide={handleBubbleMenuHide} editor={editor} onShow={onShow} />
      <EditorContent e2eIdentifiers="rich-text-editor" editor={editor} onKeyDown={handleKeyDown} onChange={handleChange} />
    </div>
  );
};

export default observer(RichTextCellEditor);
