import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { observer } from "mobx-react";

import { FeatureLayout } from "@components/FeatureLayout";
import { getMainNavMenuItems } from "@constants/navigation";
import BottomMenuPaneItems from "@router/components/Panes/BottomMenuPaneItems";
import MenuPane from "@router/components/Panes/MenuPane";
import { useMenuPane } from "@router/components/Panes/useMenuPane";
import { RouterLoading } from "@router/elements/RouterLoading";
import { EPageName } from "@router/hooks/useAppNavigate";
import appStore, { WorkspaceSyncState } from "@store/AppStore";
import { EPane } from "@store/PaneWidthStore";

import "./WorkspaceLayout.scss";
import "./OrgLevelFeatureLayout.module.scss";
import styles from "./OrgLevelFeatureLayout.module.scss";

interface IOrgLevelFeatureLayoutProps {
  currentPage: EPageName;
  children?: React.ReactNode;
}

function OrgLevelFeatureLayout(props: IOrgLevelFeatureLayoutProps) {
  const { currentPage, children } = props;
  const { isNavPaneOpen, toggleNavPane } = appStore.env;
  const { open, toggle, minSize, maxSize } = useMenuPane({ isOpen: isNavPaneOpen, toggleIsOpen: toggleNavPane, minExpandedWidth: 180 });
  const storeLoading = appStore.ui.syncState === WorkspaceSyncState.Loading;

  const { workspaceModel, env, loadWorkspaceFromDatabase } = appStore;
  const navMenuItems = getMainNavMenuItems({
    workspace: workspaceModel,
    currentPage,
    isSidebarOpen: open,
    toggleSidebar: toggle,
  });

  useEffect(() => {
    // so the "Favorites" section can be displayed in the sidebar
    if (!workspaceModel) {
      const mostRecentWorkspace = env.recentlyViewedWorkspaces[0];
      if (mostRecentWorkspace) {
        loadWorkspaceFromDatabase(mostRecentWorkspace.id);
      }
    }
  }, [env.recentlyViewedWorkspaces, loadWorkspaceFromDatabase, workspaceModel]);

  if (storeLoading) {
    return <RouterLoading disableAnimation />;
  }

  return (
    <FeatureLayout
      paneWidth={appStore.env.paneWidth.nav}
      minSize={minSize}
      maxSize={maxSize}
      isSidePanelOpen={open}
      onPaneWidthChange={(newSize: number) => appStore.env.paneWidth.updatePaneWidth(EPane.NAV, newSize)}
    >
      <MenuPane
        className={styles.inboxLayoutSideMenu}
        containerClassName={styles.inboxLayoutSideMenuContainer}
        open={open}
        title="Navigation"
        toggle={toggle}
        menuItems={navMenuItems}
        collapsedContent={<BottomMenuPaneItems minimal />}
      >
        <BottomMenuPaneItems />
      </MenuPane>
      {children ? children : <Outlet />}
    </FeatureLayout>
  );
}

export default observer(OrgLevelFeatureLayout);
