import { Editor, FocusPosition } from "@tiptap/core";

import { focusReportBlockById, getCheckboxComponent } from "@components/Reports/Editor/utils";
import { reportBlockHeadingTypes } from "@components/Reports/Page/utils";
import { RollupEditorType } from "@rollup-types/editor";
import appStore from "@store/AppStore";
import { IReportBlock } from "@store/ReportBlockStore";

const getTopLevelTag = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const topLevelElement = doc.body.firstElementChild;
  return topLevelElement ? topLevelElement.tagName.toLowerCase() : "";
};

const isFocusableBlockType = (type: RollupEditorType): boolean => {
  return type !== RollupEditorType.image && type !== RollupEditorType.hr;
};

const isMergeableBlockType = (type: RollupEditorType): boolean => {
  return type !== RollupEditorType.image && type !== RollupEditorType.hr && type !== RollupEditorType.table;
};

export function moveCaretToStart(element: HTMLDivElement, position: FocusPosition = "start"): void {
  const range = document.createRange();
  const sel = window.getSelection();
  range.setStart(element, 0);
  range.setEnd(element, 0);
  sel?.removeAllRanges();
  sel?.addRange(range);
  // We can access ProseMirror editor object from the start container;
  const editor = (range.startContainer as unknown as { editor: Editor }).editor as Editor;
  editor.commands.focus(position);
}

export const mergeContentWithPreviousBlock = (block: IReportBlock, blocks: IReportBlock[], content: string, depth = 1) => {
  const currentBlockIndex = blocks.findIndex(b => b.id === block.id) || 0;
  const previousBlock = currentBlockIndex ? blocks.at(currentBlockIndex - depth) : undefined;

  if (!previousBlock) {
    return;
  }

  // if previous block is empty just remove it
  const previousEditor = appStore.env.editors.get(previousBlock.id);
  if (previousEditor?.isEmpty) {
    appStore.workspaceModel?.deleteReportBlock(previousBlock);
    focusReportBlockById(block.id, false);
    return;
  }

  if (!isMergeableBlockType(previousBlock.type) && currentBlockIndex > 0) {
    mergeContentWithPreviousBlock(block, blocks, content, depth + 1);
    return;
  }

  let cleanContent = "";

  // remove default tag wrappers to merge text in one line
  const sourceBlockTopLevelTagName = getTopLevelTag(content);
  const sourceBlockTopLevelOpeningTag = `<${sourceBlockTopLevelTagName}>`;
  const sourceBlockTopLevelClosingTag = `</${sourceBlockTopLevelTagName}>`;
  if (content.startsWith(sourceBlockTopLevelOpeningTag)) {
    cleanContent = content.substring(sourceBlockTopLevelOpeningTag.length);
  }

  if (content.endsWith(sourceBlockTopLevelClosingTag)) {
    cleanContent = cleanContent.substring(0, cleanContent.length - sourceBlockTopLevelClosingTag.length);
  }

  // we want to get the top level tag of the previous block to reuse the same for the merging block
  let cleanTargetContent = previousEditor?.getHTML() || "";
  const previousBlockTopLevelTagName = getTopLevelTag(cleanTargetContent);

  if (previousBlockTopLevelTagName) {
    const previousBlockTopLevelClosingTag = `</${previousBlockTopLevelTagName}>`;
    // assign the closing tag to the content to be merged
    cleanContent += previousBlockTopLevelClosingTag;

    // remove the closing tag from the previous block content
    cleanTargetContent = cleanTargetContent.substring(0, cleanTargetContent.length - previousBlockTopLevelClosingTag.length);

    // remove </p> tag from the end of the previous block content as well to make sure there are no new lines
    if (cleanTargetContent.endsWith("</p>")) {
      cleanTargetContent = cleanTargetContent.substring(0, cleanTargetContent.length - 4);
    }
  }

  // remember position to move focus back after merging at the end of the previous block
  previousEditor?.commands.focus("end");
  const savedEndPosition = previousEditor?.state.selection.$anchor.pos || 1;
  previousEditor
    ?.chain()
    .focus()
    .setContent(cleanTargetContent + cleanContent)
    .run();
  setTimeout(() => {
    previousEditor?.commands.focus(savedEndPosition);
  }, 0);
  appStore.workspaceModel?.deleteReportBlock(block);
};

export const focusPreviousBlock = <T extends { id: string; type: RollupEditorType }>(block: T, blocks: T[]) => {
  const currentBlockIndex = blocks.findIndex(b => b.id === block.id) || 0;
  const previousIndex = Math.max(0, currentBlockIndex - 1);
  const previousBlock = blocks.at(previousIndex) || "";

  if (!previousBlock) {
    return;
  }

  if (!isFocusableBlockType(previousBlock.type) && currentBlockIndex > 0) {
    focusPreviousBlock(previousBlock, blocks);
    return;
  }

  const previousEditor = appStore.env.editors.get(previousBlock.id);
  previousEditor?.commands.focus("end");
};

export const focusNextBlock = <T extends { id: string; type: RollupEditorType }>(block: T, blocks: T[]) => {
  const currentBlockIndex = blocks.findIndex(b => b.id === block.id) || 0;
  const nextBlock = blocks.at(currentBlockIndex + 1) || "";

  if (!nextBlock) {
    return;
  }
  if (!isFocusableBlockType(nextBlock.type)) {
    focusNextBlock(nextBlock, blocks);
  }

  const nextEditor = appStore.env.editors.get(nextBlock.id);
  nextEditor?.commands.focus("end");
};

export const getCaretPosition = (editor: Editor | null) => editor?.state.selection.$anchor.pos || 1;

export const isEmpty = (editor: Editor | null) => {
  return editor?.getHTML().trim() === "<p></p>";
};

export const htmlStringToText = (htmlString: string): string => {
  const fragment = document.createRange().createContextualFragment(htmlString);
  return fragment.textContent || "";
};

export const getNewBlockContent = (content: string, type: RollupEditorType) => {
  if (reportBlockHeadingTypes.includes(type)) {
    return getHeadingContent(type, content);
  } else if (type === RollupEditorType.quote) {
    return `<blockquote>${content}</blockquote>`;
  } else if (type === RollupEditorType.checklist) {
    return getCheckboxComponent(false, content);
  }
  return content;
};

export const getContentWithoutOuterTag = (content: string) => {
  const rootTagRegex = /^<\w+>(?<content>.*)<\/\w+>$/;
  const result = content.match(rootTagRegex);
  return result?.groups?.content || content;
};

export const changeBlockTypes = <
  T extends { id: string; updateText: (value: string) => void; updateType: (value: RollupEditorType) => void },
>(
  blocks: T[],
  type: RollupEditorType
) => {
  blocks.forEach(block => {
    const editor = appStore.env.editors.get(block.id);
    const currentContent = editor?.getHTML() || "";
    const contentWithoutOuterTag = getContentWithoutOuterTag(currentContent);
    const newContent = getNewBlockContent(contentWithoutOuterTag, type);
    block.updateText(newContent);
    block.updateType(type);
  });
};

export const getHeadingContent = (type: RollupEditorType, text?: string) => {
  const regex = /^<h[1-6]>.*<\/h[1-6]>$/;
  const textAlreadyHasTag = text && regex.test(text);
  if (textAlreadyHasTag) {
    return text;
  }
  const headingNumber = type.match(/\d/)?.[0];
  if (headingNumber) {
    return `<h${headingNumber}>${text}</h${headingNumber}>`;
  }
  return `<h1>${text}</h1>`;
};
