import { CatalogItemTableColumn } from "@components/CatalogItems/Table/types";
import { CatalogItem } from "@rollup-api/models/catalogItem/catalogItem.model";
import { CatalogItemVersion } from "@rollup-api/models/catalogItem/catalogItemVersion.model";

export enum CatalogItemType {
  Product = "product",
  Part = "part",
  Assembly = "assembly",
}

export enum CatalogItemSource {
  InHouse = "In-House",
  Sourced = "Sourced",
}

export enum CatalogItemStatus {
  InReview = "inReview",
  Active = "active",
  Suspended = "suspended",
  Pending = "pending",
  Archived = "archived",
}

export enum ThumbnailStatus {
  Failed = "Failed",
  Pending = "Pending",
  Ready = "Ready",
}

export type CatalogItemChildRef = {
  name?: string;
  refId?: string;
  nodeId?: string;
  catalogItemId: string;
};

export class UpdateCatalogItemDto {
  partNumber?: string;
  partNumberSchemaId?: string;
  name?: string;
  description?: string;
  type?: CatalogItemType;
  imageUrl?: string;
  isDraft?: boolean;
  mainAttachmentId?: string;
  source?: CatalogItemSource;
  status?: CatalogItemStatus;
}

export class CreateCatalogItemVersionDto {
  id!: string;
  catalogItemId!: string;
  isDraft?: boolean;
  material?: string;
  leadTime?: string;
  quantity?: number;
  status?: CatalogItemStatus;
  weight?: number;
  weightUnit?: string;
  cost?: number;
  costCurrency?: string;
  supplier?: string;
  revisionName?: string;
  revisionCode?: string;
  comment?: string;
  attachmentId?: string;
}

export class CreateCatalogItemReferenceDto {
  id!: string;
  parentCatalogItemId!: string;
  referencedCatalogItemId!: string;
  name?: string;
  refId?: string;
  nodeId?: string;
}

export type CreateCatalogItemDto = Omit<CreateCatalogItemVersionDto, "id" | "catalogItemId"> & {
  id: string;
  name: string;
  description?: string;
  parentItemId?: string;
  workspaceId?: string;
  partNumber?: string;
  partNumberSchemaId?: string;
  imageUrl?: string;
  type: CatalogItemType;
  source?: CatalogItemSource;
};

export class UpdateCatalogItemVersionDto {
  material?: string;
  leadTime?: string;
  quantity?: number;
  weight?: number;
  weightUnit?: string;
  cost?: number;
  costCurrency?: string;
  supplier?: string;
  revisionName?: string;
  revisionCode?: string;
  comment?: string;
  isDraft?: boolean;
  attachmentId?: string;
}

export type CreateCatalogItemResponse = { catalogItem: CatalogItem; catalogItemVersion: CatalogItemVersion };

export type CatalogItemCsvColumnMap = {
  [CatalogItemTableColumn.PartNumber]: string;
  [CatalogItemTableColumn.Name]: string;
  [CatalogItemTableColumn.PartType]: string;
  [CatalogItemTableColumn.Index]: string;
  [CatalogItemTableColumn.Status]: string;
  [CatalogItemTableColumn.Material]: string;
  [CatalogItemTableColumn.Weight]: string;
  [CatalogItemTableColumn.Cost]: string;
  [CatalogItemTableColumn.Quantity]: string;
  [CatalogItemTableColumn.Supplier]: string;
  [CatalogItemTableColumn.Description]: string;
  [CatalogItemTableColumn.LeadTime]: string;
  [CatalogItemTableColumn.Id]: string;
  [CatalogItemTableColumn.Parent]: string;
};
