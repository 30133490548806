import { CatalogItem, CatalogItemRemoved } from "@rollup-api/models/catalogItem/catalogItem.model";
import {
  CatalogItemChildRef,
  CreateCatalogItemDto,
  CreateCatalogItemResponse,
  UpdateCatalogItemDto,
} from "@rollup-api/models/catalogItem/catalogItemDtos";
import { getFormDataFromObject } from "@utilities/FormData";

import { HttpClient, ParentClient } from "./client";

export class CatalogItems extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private readonly endpoint = "/catalog-items";

  public create = async (dto: CreateCatalogItemDto) => {
    try {
      const data = getFormDataFromObject(dto);
      const res = await this.instance.post<CreateCatalogItemResponse>(this.endpoint, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  public update = async (id: string, data: UpdateCatalogItemDto) => {
    try {
      const res = await this.instance.patch<CatalogItem>(`${this.endpoint}/${id}`, data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  public createFromBlocks = async (blockIds: string[], workspaceId?: string) => {
    try {
      const res = await this.instance.post<CreateCatalogItemResponse[]>(`${this.endpoint}/blocks`, { blockIds, workspaceId });
      return res.data;
    } catch (err) {
      console.error(err);
      throw new Error("Failed to create catalog items from blocks");
    }
  };

  public remove = async (id: string) => {
    try {
      const res = await this.instance.delete<CatalogItemRemoved>(`${this.endpoint}/${id}`);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  public removeRefs = async (id: string, childRefs: CatalogItemChildRef[]) => {
    try {
      const res = await this.instance.post<CatalogItem>(`${this.endpoint}/remove-refs/${id}`, { childRefs });
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  public bulkRemove = async (ids: string[]) => {
    try {
      const res = await this.instance.post<CatalogItemRemoved[]>(`${this.endpoint}/bulk-remove`, { ids });
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  public setPartNumberFromSchema = async (id: string, schemaId: string) => {
    try {
      const res = await this.instance.patch<string>(`${this.endpoint}/${id}/set-part-number`, { schemaId });
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  public retrieveList = async (): Promise<CatalogItem[]> => {
    try {
      const res = await this.instance.get<CatalogItem[]>(this.endpoint);
      return res.data;
    } catch (err) {
      console.error(err);
      return [];
    }
  };
}
