import { ComponentProps, JSX, useEffect, useRef, useState } from "react";
import { FormGroup, IconName, InputGroup, Intent, PopoverPosition } from "@blueprintjs/core";
import { ItemRenderer, Suggest2 } from "@blueprintjs/select";
import classNames from "classnames";
import Fuse from "fuse.js";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { Tooltip } from "@components/Tooltip";
import { ESCAPE_KEY } from "@constants/keys";
import { LabeledEntity } from "@store/WorkspaceStore";

import styles from "./CreateNewInput.module.scss";

const AutoCompleteSuggest = Suggest2<LabeledEntity>;

type CreateNewInputProps = {
  className?: string;
  formGroupClassName?: string;
  placeholder?: string;
  createNewTextPrefix?: string;
  disabled?: boolean;
  autocompleteEntries?: LabeledEntity[];
  autoFocus?: boolean;
  hideIcon?: boolean;
  icon?: IconName;
  inputProps?: ComponentProps<typeof AutoCompleteSuggest>["inputProps"];
  rightElement?: JSX.Element;
  tryCreate?(label: string, id?: string): Promise<boolean> | boolean;
  validation?(label: string): { isValid: boolean; message?: string };
  validateBeforeSubmit?(label: string): { isValid: boolean; message?: string };
  onBlur?(): void;
};

const CreateNewInput = (props: CreateNewInputProps) => {
  const { tryCreate, autocompleteEntries, validation, validateBeforeSubmit } = props;
  const { hideIcon, icon, rightElement, onBlur } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState("");
  const [helperText, setHelperText] = useState("");
  const [intent, setIntent] = useState<Intent>(Intent.NONE);

  const handleBlur = () => {
    clearValidation();
    onBlur?.();
  };

  const clearValidation = () => {
    setHelperText("");
    setIntent(Intent.NONE);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);

    if (validation) {
      const validationResult = validation(event.target.value);

      if (validationResult.isValid) {
        clearValidation();
      } else {
        setHelperText(validationResult.message || "");
        setIntent(Intent.WARNING);
      }
    } else if (helperText) {
      clearValidation();
    }
  };

  const renderRightElement = () => {
    if (rightElement) {
      return rightElement;
    }

    if (value) {
      return (
        <Tooltip content="Clear">
          <Button onClick={() => setValue("")} icon="cross" e2eIdentifiers="clear" minimal />
        </Tooltip>
      );
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (props.autoFocus) {
        inputRef.current?.focus();
      }
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [props.autoFocus]);

  const handleEnterKeyDown = () => {
    const validationResult = validateBeforeSubmit?.(value);

    if (validationResult?.isValid === false) {
      setHelperText(validationResult.message ?? "");
      setIntent(Intent.WARNING);
      return;
    }

    const success = tryCreate?.(value);
    if (success) {
      setValue("");
      setIntent(Intent.NONE);
    } else {
      setIntent(Intent.WARNING);
    }
  };

  // Standard input group if there are no autocomplete entries
  if (!autocompleteEntries) {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        handleEnterKeyDown();
      } else if (event.key === ESCAPE_KEY) {
        setValue("");
        setIntent(Intent.NONE);
        inputRef?.current?.blur();
      }
    };

    return (
      <FormGroup helperText={helperText} className={classNames(styles.createNewInputFormGroup, props.formGroupClassName)}>
        <InputGroup
          {...(!hideIcon && { leftIcon: icon || "small-plus" })}
          rightElement={renderRightElement()}
          className={classNames(styles.createNewInput, props.className)}
          inputRef={inputRef}
          value={value}
          intent={intent}
          placeholder={props.placeholder}
          disabled={props.disabled}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          autoFocus={props.autoFocus}
        />
      </FormGroup>
    );
  } else {
    const renderAutoCompleteItem: ItemRenderer<LabeledEntity> = (entry, { handleClick, modifiers }) => {
      if (!entry || !modifiers.matchesPredicate) {
        return null;
      }
      return (
        <MenuItem
          selected={modifiers.active}
          label={entry.description}
          disabled={entry.disabled}
          key={entry.id}
          onClick={handleClick}
          text={entry.label}
          e2eIdentifiers={[entry.label]}
        />
      );
    };

    const createNewItemRenderer = (query: string, active: boolean, handleClick: React.MouseEventHandler<HTMLElement>) => {
      if (autocompleteEntries.find(f => f.label.toLowerCase() === query.toLowerCase())) {
        return <MenuItem disabled icon="error" text={`"${query}" already exists`} e2eIdentifiers={[`${query}-already-exists`]} />;
      }
      return (
        <MenuItem
          selected={active}
          onClick={handleClick}
          icon="add"
          text={`${props.createNewTextPrefix ?? "Create new item"} "${query}"`}
          e2eIdentifiers="create-new-item"
        />
      );
    };

    const fuse = new Fuse(autocompleteEntries, {
      isCaseSensitive: false,
      findAllMatches: true,
      keys: ["label"],
      threshold: 0.8,
      ignoreLocation: true,
    });

    const queryCallback = (query: string) => {
      if (!query.trim()) {
        return autocompleteEntries.filter(r => !r.disabled).slice(0, 10);
      }

      const fuseResult = fuse.search(query, { limit: 10 });
      return fuseResult.map(r => r.item).filter(r => !r.disabled);
    };

    const handleItemSelected = async (item: LabeledEntity) => {
      const success = await tryCreate?.(item.label, item.id);
      if (success) {
        setIntent(Intent.NONE);
      } else {
        setIntent(Intent.WARNING);
      }
      if (inputRef?.current) {
        inputRef.current.focus();
      }
    };

    const handleItemCreated = (query: string) => {
      return {
        label: query,
      };
    };

    return (
      <AutoCompleteSuggest
        disabled={props.disabled}
        className="create-new-input"
        itemRenderer={renderAutoCompleteItem}
        onItemSelect={handleItemSelected}
        items={autocompleteEntries}
        itemsEqual={(a, b) => a.label.toLowerCase() === b.label.toLowerCase()}
        popoverProps={{
          minimal: true,
          matchTargetWidth: true,
          position: PopoverPosition.BOTTOM,
        }}
        resetOnQuery
        resetOnSelect
        inputProps={{
          placeholder: props.placeholder,
          asyncControl: true,
          ...(!hideIcon && { leftIcon: "small-plus" }),
          inputRef,
          intent,
          onBlur: handleBlur,
          autoFocus: props.autoFocus,
          ...props.inputProps,
        }}
        itemListPredicate={queryCallback}
        inputValueRenderer={item => item.label}
        createNewItemPosition="first"
        createNewItemRenderer={createNewItemRenderer}
        createNewItemFromQuery={handleItemCreated}
        onQueryChange={queryCallback}
        selectedItem={null}
      />
    );
  }
};

export default observer(CreateNewInput);
