import { useNavigate } from "react-router-dom";
import { NonIdealState } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import Pane from "@router/components/Panes/Pane";
import appStore from "@store/AppStore";

import { useSelectInitialDocument } from "../hooks/useSelectInitialDocument";

/** Main function. */
const RequirementsIndex = () => {
  const navigate = useNavigate();
  const workspace = useWorkspace();

  useSelectInitialDocument({
    documentUrl: "requirements",
    documentPageMap: workspace.requirementsModule.requirementsPageMap,
    setActiveDocument: appStore.env.setActiveRequirementsDocument,
  });

  const handleNewRequirementsDocument = async () => {
    const newDoc = await workspace.requirementsModule.createRequirementsPage();
    if (newDoc) {
      appStore.env.setActiveRequirementsDocument(newDoc.id);
      navigate(newDoc.id);
    }
  };

  return (
    <Pane className="component--requirements-index">
      {workspace.requirementsModule.values.length === 0 && (
        <NonIdealState
          title="This workspace doesn't have any requirements documents"
          description="Create a new one to get started."
          action={
            <Button
              icon="plus"
              text="Create a new document"
              onClick={handleNewRequirementsDocument}
              e2eIdentifiers={["requirements-frame", "create"]}
            />
          }
        />
      )}
    </Pane>
  );
};

/** Exports. */
export default observer(RequirementsIndex);
