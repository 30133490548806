import { Position, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import AttachmentIcon from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/AttachmentIcon";
import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";
import { IAttachment } from "@store/AttachmentStore";

interface IAttachmentTagProps {
  attachment: IAttachment;
  className?: string;
  labelClassName?: string;
}

const AttachmentTag = (props: IAttachmentTagProps) => {
  const { attachment, className, labelClassName } = props;
  const { getAttachmentLink } = useAppNavigate();

  const handleClick = () => {
    appStore.ui.setAttachmentDetails(attachment.id);
  };

  const renderContent = () => (
    <>
      <AttachmentIcon attachment={attachment} iconSize={16} height={16} />
      <span className={labelClassName}>{attachment.label}</span>
    </>
  );

  if (appStore.ui.isPrintingPage) {
    return (
      <a className={className} href={getAttachmentLink(attachment.id)}>
        {renderContent()}
      </a>
    );
  }

  return (
    <Tooltip hoverOpenDelay={500} placement={Position.BOTTOM} content={attachment.label}>
      <div className={className} onClick={handleClick}>
        {renderContent()}
      </div>
    </Tooltip>
  );
};

export default observer(AttachmentTag);
