import omit from "lodash/omit";

import { TNavigationLinkConfigs } from "@constants/navigation";

export const removeDisabledMenuItems = (menuItems: TNavigationLinkConfigs): TNavigationLinkConfigs => {
  return menuItems
    .filter(item => !item.isDisabled?.())
    .map(item => {
      return {
        ...omit(item, "isDisabled"),
        ...(item.nestedLinks && {
          nestedLinks: item.nestedLinks.filter(nestedItem => !nestedItem.isDisabled?.()).map(i => omit(i, "isDisabled")),
        }),
      };
    });
};
