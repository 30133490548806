import { useEffect } from "react";
import { observer } from "mobx-react";

import { AnnotationThread } from "@components/AnnotationThread";
import { EntityAnnotationItem } from "@components/EntityAnnotationItem";
import { Loading } from "@components/Loading";
import { IAnnotationList } from "@store/AnnotationListStore";
import appStore from "@store/AppStore";
import { IComment } from "@store/CommentStore";
import { StoreType } from "@store/types";

import styles from "./EntityAnnotationList.module.scss";

interface IEntityAnnotationListProps {
  annotationList: IAnnotationList;
  storeType: StoreType;
  lastFocusedCommentId?: string;
  disableAutoFocus?: boolean;
  onFocusComment?(commentId: string): void;
  onEditingComment?(isEditing: boolean): void;
  onCancel?(): void;
}

const EntityAnnotationList = (props: IEntityAnnotationListProps) => {
  const { annotationList, storeType, lastFocusedCommentId } = props;
  const { disableAutoFocus, onFocusComment, onEditingComment, onCancel } = props;
  const { annotations } = annotationList;
  const workspaceId = appStore.workspaceModel?.id ?? "";

  useEffect(() => {
    if (!annotations.length) {
      annotationList.addAnnotation(workspaceId, storeType);
    }
  }, [annotationList, annotations.length, storeType, workspaceId]);

  const renderAnnotationItem = (comment: IComment) => {
    return (
      <EntityAnnotationItem
        key={comment.id}
        comment={comment}
        lastFocusedCommentId={lastFocusedCommentId}
        onFocusComment={onFocusComment}
        onEditingComment={onEditingComment}
      />
    );
  };

  const renderContent = () => {
    if (!annotations.length) {
      return <Loading />;
    }

    return annotations.map(annotation => {
      return (
        <AnnotationThread
          key={annotation.id}
          annotation={annotation}
          renderAnnotationItem={renderAnnotationItem}
          disableAutoFocus={disableAutoFocus}
          onCancel={onCancel}
        />
      );
    });
  };

  return <div className={styles.entityAnnotationList}>{renderContent()}</div>;
};

export default observer(EntityAnnotationList);
