import isNull from "lodash/isNull";
import omitBy from "lodash/omitBy";

import { AnalysisInput } from "@rollup-api/models/analysis/analysis-input.model";
import { AnalysisOutput } from "@rollup-api/models/analysis/analysis-output.model";
import { ExecutionEnvironmentType } from "@rollup-api/models/execution-environments/execution-environment.type";
import { EntityModelBase } from "@rollup-api/shared/entityModelBase";
import { IAnalysisSnapshotIn } from "@store/Analysis/AnalysisStore";
import { convertTimestamp } from "@utilities";

export class Analysis extends EntityModelBase {
  public readonly label!: string;
  public readonly autoRun!: boolean;
  public readonly orderIndex!: number;
  public readonly type!: ExecutionEnvironmentType;
  public readonly executionEnvironmentId!: string;
  public readonly code?: string;
  public readonly analysisInputs?: AnalysisInput[];
  public readonly analysisOutputs?: AnalysisOutput[];
  public readonly createdBy?: string;
  public readonly updatedBy?: string;
}

export function toAnalysisSnapshot(analysis: Analysis): IAnalysisSnapshotIn {
  const input = omitBy(analysis, isNull) as Analysis;
  return {
    ...input,
    createdAt: convertTimestamp(input.createdAt),
    updatedAt: convertTimestamp(input.updatedAt),
  };
}
