import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContextMenu } from "@blueprintjs/core";
import { useIsOverflowing } from "@hooks/useIsOverflowing";
import classNames from "classnames";
import { observer } from "mobx-react";

import { DocumentNameInput } from "@components/DocumentNameInput";
import { FavoriteButton } from "@components/FavoriteButton";
import MoreOptionsMenu from "@components/MoreOptionsMenu/MoreOptionsMenu";
import RequirementsSidebarContextMenu from "@components/Requirements/RequirementsSidebar/RequirementsSidebarContextMenu";
import { Tag } from "@components/Tag";
import NavLinkAsMenuItem from "@router/navigation/NavLinkAsMenuItem";
import { IRequirementsPage } from "@store/Requirements/RequirementsPageStore";
import { EntityType } from "@store/types";
import { closePopover } from "@utilities/DomUtils";
import { getDocumentIcon } from "@utilities/Icons";

import styles from "./RequirementsSidebarItem.module.scss";

interface IRequirementsSidebarItemProps {
  reqPage: IRequirementsPage;
  minimal?: boolean;
}

const RequirementsSidebarItem = (props: IRequirementsSidebarItemProps) => {
  const { reqPage, minimal } = props;
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { requirementId } = useParams();
  const labelRef = useRef<HTMLDivElement>(null);

  const isLabelOverflowing = useIsOverflowing(labelRef);

  const openRequirementsPage = (reportId: string) => {
    navigate(reportId);
  };

  const renderRightElements = (className: string) => {
    return (
      <div className={classNames(className, { [styles.requirementsSidebarItemPopoverOpen]: isPopoverOpen })}>
        <FavoriteButton entityId={reqPage.id} entityType={EntityType.RequirementsDocument} />
        <MoreOptionsMenu
          content={<RequirementsSidebarContextMenu onEnter={() => setIsPopoverOpen(false)} reqPage={reqPage} />}
          popoverProps={{ isOpen: isPopoverOpen, onInteraction: setIsPopoverOpen, placement: "bottom-end" }}
          e2eIdentifiers={reqPage.label}
          small
        />
      </div>
    );
  };

  const renderReqLabelAndId = () => {
    if (minimal) {
      return null;
    }
    return (
      <div className={styles.requirementsSidebarItemLabelIdContainer}>
        <div className={styles.requirementsSidebarItemLabel} ref={labelRef} title={isLabelOverflowing ? reqPage.label : undefined}>
          {reqPage.label}
        </div>
        <Tag className={styles.requirementsSidebarItemTag}>{reqPage.docNumber}</Tag>
      </div>
    );
  };

  const renderContent = () => {
    if (isEditing) {
      return (
        <DocumentNameInput
          className={styles.requirementsSidebarItemDocumentNameInput}
          inputClassName={styles.requirementsSidebarItemDocumentNameInputGroup}
          iconClassName={styles.requirementsSidebarItemIcon}
          icon={getDocumentIcon(EntityType.RequirementsDocument)}
          moreNav={minimal ? undefined : <RequirementsSidebarContextMenu onEnter={() => setIsPopoverOpen(false)} reqPage={reqPage} />}
          defaultValue={reqPage.label}
          onUpdate={reqPage.setLabel}
          onEnter={() => setIsEditing(false)}
          onEscape={() => setIsEditing(false)}
          onBlur={() => setIsEditing(false)}
          inputGroupProps={{ autoFocus: true }}
        />
      );
    }

    return (
      <NavLinkAsMenuItem
        className={styles.requirementsSidebarItemNavLink}
        textClassName={styles.requirementsSidebarItemLink}
        to={reqPage.id}
        text={renderReqLabelAndId()}
        icon={getDocumentIcon(EntityType.RequirementsDocument)}
        selected={requirementId === reqPage.id}
        e2eIdentifiers={["requirements_sidebar", reqPage.label]}
        renderRightElements={minimal ? undefined : renderRightElements}
        onDoubleClick={() => setIsEditing(true)}
      />
    );
  };

  return (
    <ContextMenu
      key={reqPage.id}
      onContextMenu={() => openRequirementsPage(reqPage.id)}
      content={<RequirementsSidebarContextMenu onEnter={closePopover} reqPage={reqPage} />}
    >
      {renderContent()}
    </ContextMenu>
  );
};

export default observer(RequirementsSidebarItem);
